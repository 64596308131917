import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../ButtonElements";

import {
  ProjectsContainer,
  ProjectsH1,
  ProjectsH2,
  ProjectsWrapper,
  ProjectsCard,
  ProjectsIcon,
  ProjectsP,
  ProjectsA,
} from "./ProjectElements";

const Projects = ({
  id,
  link_1,
  link_2,
  link_3,
  link_4,
  link_5,
  link_6,
  link_7,
  link_8,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  lightBg,
  lightText,
  darkText,
  projh1,
  h2_1,
  h2_2,
  h2_3,
  h2_4,
  h2_5,
  h2_6,
  h2_7,
  h2_8,
  projectp1,
  projectp2,
  projectp3,
  projectp4,
  projectp5,
  projectp6,
  projectp7,
  projectp8,
}) => {
  return (
    <ProjectsContainer lightBg={lightBg} id={id}>
      <ProjectsH1 lightText={lightText}>{projh1}</ProjectsH1>
      <ProjectsWrapper>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_1}>
            <ProjectsIcon src={Icon1} />
            <ProjectsH2>{h2_1}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp1}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_2}>
            <ProjectsIcon src={Icon2} />
            <ProjectsH2>{h2_2}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp2}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_3}>
            <ProjectsIcon src={Icon3} />
            <ProjectsH2>{h2_3}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp3}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_4}>
            <ProjectsIcon src={Icon4} />
            <ProjectsH2>{h2_4}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp4}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_5}>
            <ProjectsIcon src={Icon5} />
            <ProjectsH2>{h2_5}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp5}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_6}>
            <ProjectsIcon src={Icon6} />
            <ProjectsH2>{h2_6}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp6}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_7}>
            <ProjectsIcon src={Icon7} />
            <ProjectsH2>{h2_7}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp7}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
        <ProjectsCard lightBg={lightBg}>
          <ProjectsA href={link_8}>
            <ProjectsIcon src={Icon8} />
            <ProjectsH2>{h2_8}</ProjectsH2>
            <ProjectsP darkText={darkText}>{projectp8}</ProjectsP>
          </ProjectsA>
        </ProjectsCard>
      </ProjectsWrapper>
    </ProjectsContainer>
  );
};

export default Projects;
