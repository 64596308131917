import React, { useState } from "react";
import Typist from "react-typist";
import Typical from "react-typical";
import Typewriter from "typewriter-effect";
import BgAnimation from "../BackgroundAnimation/BackgroundAnimation";
import {
  HeroContainer,
  dark,
  primary,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { Button } from "../ButtonElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <BgAnimation />
      </HeroBg>
      <HeroContent>
        <HeroH1>Hey, Moe here!</HeroH1>
        <HeroP>
          I'm a
          <Typewriter
            options={{
              strings: [
                "Software Developer",
                "Techie",
                "Scientist",
                "Foodie",
                "Photographer",
                "Gamer",
              ],
              deleteSpeed: "natural",
              autoStart: true,
              loop: true,
            }}
          />
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            primary="primary"
            dark="true"
          >
            Find out more {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
