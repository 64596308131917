import styled from "styled-components";

export const ProjectsContainer = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#010606" : "#f9f9f9")};
  height: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 2600px) {
    height: 1500px;
  }

  @media screen and (max-width: 2300px) {
    height: 1500px;
  }

  @media screen and (max-width: 1000px) {
    height: 1600px;
  }

  @media screen and (max-width: 768px) {
    height: 3000px;
  }

  @media screen and (max-width: 480px) {
    height: 3300px;
  }
  @media screen and (max-width: 280px) {
    height: 3500px;
  }
`;

export const ProjectsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  padding: 0 50px;
  text-decoration: none;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const ProjectsCard = styled.div`
  background: #000;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;

  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ProjectsIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

export const ProjectsH1 = styled.h1`
  color: ${({ lightText }) => (lightText ? "#fff" : "#010606")};
  font-size: 2.5rem;
  margin-bottom: 64px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    text-align: center;
  }

  @media screen and (max-width: 280px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const ProjectsH2 = styled.h2`
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  font-size: 1rem;
  margin-bottom: 10px;
  text-decoration: none;
  text-align: center;

  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

export const ProjectsP = styled.p`
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
`;

export const ProjectsA = styled.a`
  text-decoration: none;
`;
