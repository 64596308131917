import React from "react";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  SocialIconLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  Links,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        {/* <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Links</FooterLinkTitle>
                            <Links href="https://www.github.com/mkchama">Github</Links>
                            <Links href="https://www.linkedin.com/in/mohammad-chama/">Linkedin</Links> 
                            <Links href="https://www.github.com">Github</Links> 
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer> */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              mkchama
            </SocialLogo>
            <WebsiteRights>
              © {new Date().getFullYear()} mkchama | All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href={`mailto:mkchama@ucalgary.ca`}
                aria-label="Email"
              >
                <FaEnvelope />
              </SocialIconLink>
              <SocialIconLink
                href="//www.github.com/mkchama"
                target="_blank"
                aria-label="Github"
              >
                <FaGithub />
              </SocialIconLink>
              <SocialIconLink
                href="///www.linkedin.com/in/mohammad-chama"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
