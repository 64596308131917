import React from "react";
import { Button } from "../ButtonElements";
import { FaLinkedin, FaGithub } from "react-icons/fa";

import {
  InfoContainer,
  SocialIcons,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel1,
  buttonLabel2,
  img,
  alt,
  primary,
  dark,
  dark2,
  linkClick1,
  linkClick2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <SocialIcons>
                  <BtnWrap>
                    {id === "about" ? (
                      <Button
                        onClick
                        href={linkClick1}
                        target="_blank"
                        aria-label="LinkedIn"
                        text-decoration="none"
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel1}&nbsp;
                        <FaLinkedin />
                      </Button>
                    ) : null}
                  </BtnWrap>
                  <BtnWrap>
                    {id === "about" ? (
                      <Button
                        onClick
                        href={linkClick2}
                        target="_blank"
                        aria-label="University of Calgary"
                        text-decoration="none"
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel2}&nbsp;
                        <FaGithub />
                      </Button>
                    ) : null}
                  </BtnWrap>
                </SocialIcons>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
