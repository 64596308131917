export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About",
  headline: "Get to know me",
  description:
    "I'm a recent graduate from the University of Calgary in Computer Science with a focus in software development. I have profound interest in full-stack development, machine learning, human-computer interactions, and everything in between.",
  buttonLabel1: "LinkedIn",
  buttonLabel2: "Github",
  imgStart: false,
  img: require("../../images/moe.jpg").default,
  alt: "Pic",
  dark: true,
  primary: true,
  darkText: false,
  linkClick1: "//www.linkedin.com/in/mohammad-chama",
  linkClick2: "///github.com/mkchama",
};

export const homeObjTwo = {
  id: "skills",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Technical Skills",
  headline: "Data Structures and Algorithms",
  description:
    "I'm able to analyze the running time of an alogrithm using summations and reccurences via the expression of asymptotic notation. I also am able to design algorithms using Greedy, Dynamic Programming and Divide and Conquer design approaches.",
  buttonLabel: "My Work",
  imgStart: true,
  img: require("../../images/process.svg").default,
  alt: "skills",
  dark: true,
  primary: true,
  darkText: false,
  Button: false,
};

export const homeObjThree = {
  id: "projects",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Related Projects",
  headline: "Group and Individual Projects",
  description:
    "To see more of my projects and work, visit my Github to see all the latest updates.",
  buttonLabel: "Github",
  imgStart: false,
  img: require("../../images/svg-1.svg").default,
  alt: "projects",
  dark: true,
  primary: true,
  darkText: false,
  linkeClick1: "//www.linkedin.com/in/mohammad-chama",
  linkClick2: "///github.com/mkchama",
};
