export const projectObjOne = {
  id: "skills",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  projh1: "Skills",
  h2_1: "Java",
  h2_2: "Version Control",
  h2_3: "C/C++",
  h2_4: "Databases",
  h2_5: "HTML CSS Javascript",
  h2_6: "Assembly",
  h2_7: "Information Security",
  h2_8: "Cryptography",
  link_1:
    "https://www.ucalgary.ca/pubs/calendar/current/computer-science.html#3622",
  link_2:
    "https://www.ucalgary.ca/pubs/calendar/current/computer-science.html#3647",
  link_4:
    "https://www.ucalgary.ca/pubs/calendar/current/computer-science.html#3644",
  link_6:
    "https://www.ucalgary.ca/pubs/calendar/current/computer-science.html#3631",
  link_7:
    "https://www.ucalgary.ca/pubs/calendar/current/computer-science.html#30261",
  link_8:
    "https://www.ucalgary.ca/pubs/calendar/current/computer-science.html#30260",
  projectp1:
    "Proficient in all things Java. Including reflection, serialization and deserialization",
  projectp2:
    "Extensive knowledge in git, gitlab, github, source control and unit testing",
  projectp3:
    "Can create small applications and have knowledge of multithreading using libraries",
  projectp4:
    "Knowledge in database design, writing queries and differentiating between data models",
  projectp5:
    "Familiar with javascript async, AJAX, APIs, CSS styling, and HTML elements",
  projectp6: "Knowledge in writing small programs using ARMv8 assembly code",
  projectp7:
    "Knowledge in principles of Information Security involving protection mechanisms and employing program logic",
  projectp8:
    "Knowledge in cryptography, encryption, and decryption. Competence in writing software that provides cryptographic services + able to integrate existing cryptographic software libraries + packages",
  dark: true,
  primary: false,
  darkText: false,
  Icon1: require("../../images/svg-10.svg").default,
  Icon2: require("../../images/ver_control.svg").default,
  Icon3: require("../../images/svg-9.svg").default,
  Icon4: require("../../images/server.svg").default,
  Icon5: require("../../images/svg-11.svg").default,
  Icon6: require("../../images/svg-6.svg").default,
  Icon7: require("../../images/security.svg").default,
  Icon8: require("../../images/crypto.svg").default,
};

export const projectObjTwo = {
  id: "projects",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  projh1: "Projects",
  link_1: "///mkchama.github.io/cryptolytics",
  link_8: "///github.com/mkchama/Python-Projects",
  link_3: "",
  link_4: "",
  link_5: "///github.com/mkchama/Maze-Game",
  link_6: "///github.com/mkchama/EazyRecipez",
  link_7: "///github.com/mkchama/Casino-Game",
  link_2: "///github.com/mkchama/silver-robot",
  h2_1: "Cryptolytics",
  h2_8: "Python programs",
  h2_3: "This Website",
  h2_4: "Cafeteria Database",
  h2_5: "Maze Game",
  h2_6: "Eazy Recipez",
  h2_7: "Casino Card Game",
  h2_2: "Capture the Flag Game",
  projectp1:
    "Created and developed a React Website that displays information regarding the global crypto market using APIs to retrieve data and information",
  projectp8:
    "Created multiple small python programs, including hangman, movie suggestions based on movies that you have watched and liked, and candy crush.",
  projectp3: "This website was built using React, JavaScript CSS and Node.js",
  projectp4:
    "Creation of a cafeteria database management system using MySQL along with Postman",
  projectp5:
    "Created and developed a Maze Game using the SNES Controller and Frame Buffer programming done in C on the Raspberry Pi",
  projectp6:
    "Designed and developed a Recipe Application including a portfolio with designs, prototypes, user stories, and walkthroughs. Implemented using C# and WPF",
  projectp7:
    "Created and developed a Casino Card Game with a team. Includes two games; Blackjack and War. The project was implemented in Java",
  projectp2:
    "Created and developed a Capture the Flag Game using the Unreal Engine 4 and Visual Scripting. Implemented an AI using A* pathfinding and a custom behaviour tree",
  dark: true,
  primary: false,
  darkText: false,
  Icon1: require("../../images/bitcoin.svg").default,
  Icon8: require("../../images/svg-5.svg").default,
  Icon3: require("../../images/svg-13.svg").default,
  Icon4: require("../../images/diet.svg").default,
  Icon5: require("../../images/gaming.svg").default,
  Icon6: require("../../images/cooking.svg").default,
  Icon7: require("../../images/cards.svg").default,
  Icon2: require("../../images/AI.svg").default,
};
